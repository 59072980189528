import React from "react";
import  { withPrefix } from "gatsby-link";

import "../guard.less";

const SceneDetail = scene => (
  <div
    className="secondary-background product-scene"
    id={`detail-${scene.key}`}
  >
    <div className="">
      <div className="scene-text col-md-5">
        <div className="col-md-12 padding">
          <div className="text">
            <h3> 應用場景</h3>
            <h2> {scene.title}</h2>
            <p className="padding-onlytop-sm">{scene.detail}</p>
          </div>
        </div>
      </div>
      <div
        className="col-md-7 scene-img padding-leftright-null"
        style={{
          backgroundImage: `url(${withPrefix(
            "/assets/img/scene-" + scene.key + "-row.jpg"
          )})`
        }}
      />
    </div>
  </div>
);

export default SceneDetail;
