import React from "react";
import { withPrefix } from "gatsby-link";
import "./guard.less";
import SceneDetail from "./guard/_scene_detail";
import ImageBlueprint from "./img/butler-blueprint-transparent.png";
import Layout from "../components/layout"

const scenes = [
  {
    key: "home",
    title: "家居",
    desc: "可以通過語音與小程序連接來控管家居",
    o: "#detail-home",
    detail: `小馬哥1號作為智能家居的集結點,提供全方位家庭監控,安全防盜,氣體煙霧監測,自動警報和家居清掃,使用語音操控所有智能家居設備,能夠隨時隨地調整住家的照明,溫度,濕度,水溫等。`
  },
  {
    key: "elder",
    title: "養老",
    o: "#detail-elder",
    desc: "提醒定時食藥,檢杳身體機能數值",
    detail: `語音操作覆蓋所有小馬哥1號功能,是一款最易用的養老機器人,老年人的最佳生活助手。具備語音行走控制,語音視頻通話,休閒娛樂互動,小程序遠程控制,語音留言箱,健康管理,安防監控,呼救報警等功能。`
  },
  {
    key: "education",
    title: "教育",
    desc: "對接海量的視頻及閱讀內容資源",
    o: "#detail-education",
    detail: `小馬哥1號對接海量的視頻,閱讀內容資源,帶來精彩不停的教育視聽體驗,根據搜索和個人使用數據,智能推薦所需的教育內容,讓您最輕鬆的進行在線學習,且百科百問,無所不知。`
  },
  {
    key: "health",
    title: "醫療",
    desc: "支持連接第三方健康監測設備,進行健康監測",
    o: "#detail-health",
    detail: `連接第三方健康監測設備,適合家庭使用,能夠定期的獲取老年人健康數據,進行健康監測和醫療平台的連接,具備早起問候,日間和睡眠生命體徴監測,老人摔倒預警等功能。可精凖識別語音控制信息,進行鬧鐘設定,提醒定時吃藥,用餐,標準化健康管理;遠程連接專業醫療機構或醫生,實時問診,專業化醫療服務;醫療健康信息報告同步醫生和子女,人性化服務體驗。`
  },

  {
    key: "customer-service",
    title: "客服",
    desc: "支持連接第三方健康監測設備",
    o: "#detail-customer-service",
    detail: `應用自然語言處理技術(NLP),語音識別,機器學習和大數據等技術打造先進的智能客服功能,構建創新型的人機協作模式。具備機器人智能回答問題,疑難問題支持人工遠程應答,文字自動轉換為機器人聲音,人工干預不被發覺等職能客服功能。`
  },

  {
    key: "tourism",
    title: "旅遊",
    desc: "解答遊客問題及景點巡邏",
    o: "#detail-tourism",
    detail: `小馬哥1號傾力打造智慧旅遊,智慧景點。能夠定制化智能問答庫,提供景點講解,路線導覽,跟隨導遊等特色人工智能服務。為景區提供特色化旅遊體驗,如機器人給遊客合拍照片,景點24小時巡邏,意外狀況告警,機器人支付等。`
  }
  // {
  //     'key': 'security', 'title': '安防' ,
  //     'desc': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit' ,"o":"#a2"
  // },
];
const ButlerPage = () => (
  <Layout>
  <div id="main-wrap">
    {/*   Main Wrap  */}
    {/*   Page Content  */}
    <div id="page-content" className="header-static">
      {/*   Slider  */}
      <div id="flexslider" className="fullpage-wrap small">
        <ul className="slides">
          <li className="hero-slide-butler" />
        </ul>
      </div>
      {/*   END Slider  */}
      {/*   Home Wrap  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        {/* Section Scene Icon */}
        <div className="light-background">
          <div className="container">
            <div className="row no-margin padding-onlytop-lg">
              <div className="col-md-12 padding-leftright-null text-center">
                <h3 className="big margin-bottom-small"> 服務機器人 </h3>
                <h3 className="medium"> 應用場景 </h3>
              </div>
              <div className="col-md-12 text" id="scene">
                {/* Single Scene */}
                {scenes.map(scene => (
                  <div data-target={scene.o} className="col-sm-2 single-scene-tight">
                    <article>
                      <img
                        className="image"
                        src={withPrefix(
                          "/assets/img/icon-scene-" + scene.key + ".jpg"
                        )}
                        alt=""
                      />
                      <div className="content">
                        <h5 className="">{scene.title}</h5>
                        <p className="small padding-onlytop-sm">{scene.desc}</p>
                      </div>
                    </article>
                  </div>
                ))}
                {/* END Single Scene */}
              </div>
            </div>
            <div id="scene_sep" className="row no-margin">
              <div className="col-md-12 padding-leftright-null">
                <div className="text padding-onlytop-sm padding-onlybottom-sm padding-md-top-null" />
              </div>
            </div>
          </div>
        </div>
        {/* END Section Scene Icon */}
        <div>{scenes.map(scene => SceneDetail(scene))}</div>
        <div className="row no-margin padding-md">
          <div className="col-md-12 padding-leftright-null text-center">
            <h3 className="secondary-color big margin-bottom-small">
              技術規格
            </h3>
          </div>

          <div className="col-md-4">
            <div className=" padding-topbottom-null ">
              <div className="padding-onlytop-sm">
                <p className="margin-bottom margin-md-bottom-null">
                  <table className="no-border">
                    <tr>
                      <td width="40%" className="dd secondary-color">
                        尺 寸 : <br />
                        重 量 :  <br />
                        顏 色 : <br />
                        材 質 :<br />
                        電 池 : <br />
                        屏 幕 : <br />
                        攝像頭 : <br />
                        揚聲器 : <br />
                        行 動 : <br />
                        網 絡 : <br />
                      </td>
                      <td>
                      1230(H)×540(D)×500(W)mm <br />
20KG <br />
各色可選 <br />
塑料+金屬 <br />
45AH <br />
10.1寸觸控屏 <br />
前置1300萬像素 <br />
立體聲喇叭 <br />
麥克納姆全向輪 <br />
Wi-Fi 2.4G/5G <br />
                      </td>
                    </tr>
                  </table>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className=" padding-topbottom-null">
              <div className="padding-onlytop-sm">
                <p className="margin-bottom margin-md-bottom-null">
                  <table className="no-border">
                    <tr>
                      <td width="40%" className="dd secondary-color">
                      身份識別: <br />

                        傳 感 器 ：
                        <br />  <br />自 由 度 ：
                        <br />
                        速 度：
                        <br />
                        續航里程： <br />
                        可搭載配件：
                      </td>
                      <td>
                      人臉識別<br />
頭部人臉識別攝像頭×1<br />
具備方位識別功能的降噪麥陣列<br />
水平八個方向自由移動<br />
頭部可自由升降(0-50cm)<br />
1m/s 20KM<br />

聲紋識別<br />
吸塵清掃<br />
血壓測量<br />
胸部視覺攝像頭×2<br />
底盤超聲波傳感器<br />
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        <br />* 非標配項目
                      </td>
                    </tr>
                  </table>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 padding-leftright-null padding-md-top">
            <div className="text padding-topbottom-null">
              <picture>
                <img
                  src={ImageBlueprint}
                  className="blueprint img-responsive"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      {/*   END Home Wrap  */}
    </div>
    {/*   END Page Content */}
    {/*   END Main Wrap  */}
  </div>
  </Layout>
);

export default ButlerPage;
